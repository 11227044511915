import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute  } from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
            {/* Sidebar Names */}
                <SidebarLink to ='about' onClick={toggle}>About</SidebarLink>
                <SidebarLink to ='contact' onClick={toggle}>Contact</SidebarLink>
                <SidebarLink to ='services' onClick={toggle}>Bakery Menu</ SidebarLink>
                {/* <SidebarLink to ='faq' onClick={toggle}>FAQs</SidebarLink> */}
            </SidebarMenu>
            <SideBtnWrap>
            <a href="https://wa.me/c/17723627323" target="_blank" rel="noreferrer" text-decoration= "none">
            <SidebarRoute>Place Order</SidebarRoute>
            </a>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  );
};
export default Sidebar;