import styled from "styled-components";

export const ServicesContainer = styled.div`
    height: 1000px;
    display: flex;
    background: radial-gradient(#8b0000, 40%, black);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: #010606;

    @media screen and (max-width: 768px) {
        height: 1800px;
    }

    @media screen and (max-width: 480px) {
        height: 1800px;
    }
`

export const ServiceWrapper = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: space-between;
    grid-gap: 300px
    padding: 0 50px;

    @media screen and (max-width: 100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ServiceCard = styled.div`
    // background: #f4bb44;
    background: radial-gradient(#f4bb44, 96%, black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    max-height: 300px;
    padding: 10px;
    box-shadow: 0 1px 30px rgba(0,0,0,0.6);
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
`

export const ServicesIcon = styled.img`
    height: 190px;
    width: 190px;
    margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 3rem;
    color: #f4bb44;
    height: 100px;
    margin-border: 65px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1.2rem;
    color: #800000;
    font-weight: bold;
    margin-bottom: 10px;
`

export const ServicesP = styled.p`
    color: #800000;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
`