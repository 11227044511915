import React from 'react'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcon, SocialIconLink} from './FooterElements'

const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        {/* <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/signin">Contact us</FooterLink>
                            <FooterLink to="/signin">View our product line</FooterLink>
                            <FooterLink to="/signin">Coming Soon!</FooterLink>
                            <FooterLink to="/signin">Testimonials</FooterLink>
                            <FooterLink to="/signin">Kosher Standards</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/signin">Contact us</FooterLink>
                            <FooterLink to="/signin">View our product line</FooterLink>
                            <FooterLink to="/signin">Coming Soon!</FooterLink>
                            <FooterLink to="/signin">Testimonials</FooterLink>
                            <FooterLink to="/signin">Kosher Standards</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/signin">Contact us</FooterLink>
                            <FooterLink to="/signin">View our product line</FooterLink>
                            <FooterLink to="/signin">Coming Soon!</FooterLink>
                            <FooterLink to="/signin">Testimonials</FooterLink>
                            <FooterLink to="/signin">Kosher Standards</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/signin">Contact us</FooterLink>
                            <FooterLink to="/signin">View our product line</FooterLink>
                            <FooterLink to="/signin">Coming Soon!</FooterLink>
                            <FooterLink to="/signin">Testimonials</FooterLink>
                            <FooterLink to="/signin">Kosher Standards</FooterLink> */}
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/'>
                        Breads and Co.
                    </SocialLogo>
                    <WebsiteRights> Breads and Co. © {new Date().getFullYear()} All rights reserved.  </WebsiteRights>
                <SocialIcon>
                    <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="//www.instagram.com/breadsandcofl?igshid=NDc0ODY0MjQ=" target="_blank" aria-label="Instagram">
                        <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink href="https://wa.me/c/17723627323" target="_blank" aria-label="WhatsApp">
                        <FaWhatsapp />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="YouTube">
                        <FaYoutube />
                    </SocialIconLink>
                </SocialIcon>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer