import React, {useState} from 'react'
import { useEffect } from 'react';
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import{ Nav, NavbarContainer, NavLogo, MobileIcon, NavItem, NavMenu, NavLinks, NavBtn, NavBtnLink } from './NavbarElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = ()=> {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#f4bb44'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>
          <img src={require('../../images/LOGO.png')} width="180  " height="65" alt='Breads & Co.'/>
          {/* <img
        src="/images/3.jpg"
       alt=""
       width="22px"
        height="24px"
        className="d-inline-block align-top"/> */}
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
              to='about'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              >
              About Us </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
              to='contact'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              > Contact </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
              to='services'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              > Bakery Menu </NavLinks>
            </NavItem>
            {/* <NavItem>
              <NavLinks
              to='faq'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              > FAQs </NavLinks>
            </NavItem> */}
          </NavMenu>
          <NavBtn>
          <a href="https://wa.me/c/17723627323" target="_blank" rel="noreferrer" text-decoration= "none">
            <NavBtnLink>Place order</NavBtnLink>
            </a>
          </NavBtn>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar