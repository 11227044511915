import React from 'react';
import { ServicesContainer, ServicesH1, ServiceWrapper, ServiceCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';
import Icon1 from '../../images/challah.jpg';
import Icon2 from '../../images/rug.jpg';
import Icon3 from '../../images/Babka.jpg';
import Icon4 from '../../images/sourdough.jpg';
import Icon5 from '../../images/amaretto.jpg';
import Icon6 from '../../images/dips.jpg';

const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1>Our Products</ServicesH1>
        <ServiceWrapper>
            <ServiceCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>Challah</ServicesH2>
                <ServicesP>Heimishe Challah and Challah rolls!</ServicesP>
            </ServiceCard>
            <ServiceCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Rugelech</ServicesH2>
                <ServicesP>Flaky dough or regular!</ServicesP>
            </ServiceCard>
            <ServiceCard>
                <ServicesIcon src={Icon3}/>
                <ServicesH2>Babka</ServicesH2>
                <ServicesP>Delicious moist Babka cake!</ServicesP>
            </ServiceCard>
            <ServiceCard>
                <ServicesIcon src={Icon4}/>
                <ServicesH2>Sourdough</ServicesH2>
                <ServicesP>Try our special Sourdough</ServicesP>
            </ServiceCard>
            <ServiceCard>
                <ServicesIcon src={Icon5}/>
                <ServicesH2>Amaretto Cake</ServicesH2>
                <ServicesP>Various icing options available</ServicesP>
            </ServiceCard>
            <ServiceCard>
                <ServicesIcon src={Icon6}/>
                <ServicesH2>Freshly made dips</ServicesH2>
                <ServicesP>Hummus, Baba Ganoush, Tahini and More!</ServicesP>
            </ServiceCard>
        </ServiceWrapper>
    </ServicesContainer>
  )
}

export default Services