import React, {useState} from 'react';
// import { Button } from 'react-scroll';
// import Video from '../../video/video.mp4';
import { Button } from '../ButtonElement';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';


const HeroSection = () => {
  const [hover, setHover] = useState(false)

const onHover = () => {
  setHover(!hover)
}


  return (
    <HeroContainer>
        <HeroBg>
        {/* <img class= "kosher" src={require('../../images/kosher.png')} width="180" height="65" justify-content="left"  alt="kosher"/> */}
            {/* <VideoBg autoplay loop muted src={Video} type='video.mp4' /> */}
        </HeroBg>
        <HeroContent>
          {/* <crcImage>
          <img src={require('../../images/CRC.jpg')} alt="CRC"/>
          </crcImage> */}
          <HeroH1>Breads & Co., Florida's finest <br></br>artisan Kosher bakery.</HeroH1>
          <HeroP>
            With the freshest breads and cakes being baked; along with an extensive catering selection, we have a little something for everyone.
          </HeroP>
          <HeroBtnWrapper>
            <a style={{textDecoration: 'none'}}href="//www.instagram.com/breadsandcofl?igshid=NDc0ODY0MjQ=" target="_blank" rel="noreferrer">
            <Button onMouseEnter={onHover} onMouseLeave={onHover}
            primary='true'
            dark='true'
            >
              Visit our news feed! {hover ? <ArrowForward /> : <ArrowRight/>}
            </Button>
            </a>
          </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection