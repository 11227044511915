export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Breads and Co.',
    headline: 'A quintessential Florida bakery known for producing artisan, handmade breads and pastries, baked in small batches throughout the day to ensure optimum freshness.',
    description: 'Catering to every occasion and events with custom ordering. Choose from a wide range of authentic Kosher breads, cakes and the most delicious ready to eat dips. Serving the community with the highest standards of Kashrus yielding to the highest quality of baked goods.Reach out to us to learn about our delivery options.',
    // buttonLabel: 'Whatsapp us',
    imgStart: false,
    img: require('../../images/kosher.png'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Visit our Bakery at: ',
    headline: '5978 SW 40th Ave, Fort Lauderdale, FL 33314 (772)-362-7323 orders@breadsandco.com'   ,
    description: 'We also deliver, place your order with us to learn more!',
    buttonLabel: 'Reach out to us here',
    imgStart: false,
    img: require('../../images/challahadd.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
};

export const homeObjThree = {
    id: 'faq',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Breads and Co.',
    headline: 'The best Kosher Bakery in Florida is now Open!',
    description: 'We are now bringing your freshly baked breads to you doorstep call us for home delivery.',
    buttonLabel: 'Whatsapp us',
    imgStart: true,
    img: require('../../images/4.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

