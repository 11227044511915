import './App.css';
import React from 'react';
import{ BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages';
import { SigninPage } from './pages/signin';
import { Helmet } from 'react-helmet';

function App() {
  return (
    
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Breads & Co.</title>
        <link rel="canonical" href="https://breadsandco.com/" />
        <meta name="description" content="Kosher bakery in Florida(Breads, Challah, Sourdough, Ruglech, cakes, Babka and dips like hummus, Tahini, etc." />
    </Helmet>
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/contact" element={<SigninPage/>} exact />
      </Routes>
    </Router>
  );
}

export default App;
